#services #content{
    gap: 1rem;
}

#services #content
 #content-divider span{
    border: solid 2px var(--navy-blue);
}

#services-deck{
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

#services-deck .icon-card #card-content{
    align-items: center;
    margin: 0;
    justify-content: center;
    width: 100%;
    padding: 3px;
}
#services-deck .icon-card:first-child {
    border-left: 2px solid var(--navy-blue);
}
#services-deck .icon-card {
    border-right: 2px solid var(--navy-blue);
    width: calc(100%/4);
    
}

#services-deck .icon-card #card-content hr{
width: 100%;
height: 1px;
background-color: var(--navy-blue);
border: 0;
padding: 0;
margin: 0;
}



#icon-section{
    width: 100%;
    justify-content: space-around;
    align-items: start;
 
}
#icon-words{
   font-weight: 600;
   gap: 1rem;
}